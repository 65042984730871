//Hooks
import useSidebarLinks from '../hooks/useSidebarLinks';
import useLocationSwitcherProps from '../hooks/useLocationSwitcherProps';
import { useNavigate } from 'react-router-dom';
//Components
import { Sidebar, ISidebar, ISidebarLink } from 'spark-component-library';
//Store
import { useContext } from 'react';
import { UserContext } from '../../../Users/context/Users.context';
import { SidebarContext } from '../Sidebar.context';
import { KeycloakContext } from '../../Auth/providers/KeycloakProvider';

const SideBar = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const sidebarProvider = useContext(SidebarContext);
  const { logout } = useContext(KeycloakContext);

  const sidebarLinks: ISidebarLink[] = useSidebarLinks();

  const handleSideBarClick = (path: string) => {
    navigate(path, { replace: true });
    sidebarProvider?.setIsMobileCollapsed(true);
  };

  const handleLogout = () => {
    if (logout) {
      logout();
    }
  };

  const sidebarProps: ISidebar = {
    helpLink: 'https://www.help.evpassport.com/hc/en-us',
    userName: user?.data.name,
    onClick: path => handleSideBarClick(path),
    sidebarLinks: sidebarLinks,
    onLogout: () => handleLogout(),
    locationSwitcherProps: useLocationSwitcherProps(),
    isCollapsed: sidebarProvider?.isCollapsed!,
    setIsCollapsed: sidebarProvider?.setIsCollapsed!,
    isMobileCollapsed: sidebarProvider?.isMobileCollapsed!,
    setIsMobileCollapsed: sidebarProvider?.setIsMobileCollapsed!,
  };

  return <Sidebar {...sidebarProps} />;
};

export default SideBar;
