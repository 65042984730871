import ReactDOM from 'react-dom';
import AppEntry from './domains/App/AppEntry/AppEntry';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import AppProviders from './App.providers';
//This is supposed to be public. (https://mui.com/x/introduction/licensing/#security)
LicenseInfo.setLicenseKey(
  '23ddd38a135791e54cc38ecd2f35f0bbTz05MzM4MCxFPTE3NTEyMDA2MDQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);


ReactDOM.render(
  <BrowserRouter>
    {/* <GlobalStyles styles={RootStyles} /> */}
      <AppProviders>
        <AppEntry />
      </AppProviders>
  </BrowserRouter>,
  document.getElementById('root')
);
