import { FooterContainer, FooterElement, FooterLink, FooterList } from './SignIn.styles';
import {REACT_APP_BASE_URL} from './../../../../../config'
export const Footer = () => (
  <FooterContainer>
    <FooterList title={`baseUrl: ${REACT_APP_BASE_URL}`}>
      <FooterElement>
        <FooterLink href='https://www.evpassport.com/legal/master-site-agreement' target='_blank' rel='noreferrer'>
          Master Services Agreement
        </FooterLink>
      </FooterElement>
      <FooterElement>
        <FooterLink href='https://www.evpassport.com/legal/terms-and-conditions' target='_blank' rel='noreferrer'>
          Terms and Conditions
        </FooterLink>
      </FooterElement>
      <FooterElement>
        <FooterLink href='https://www.evpassport.com/legal/privacy-policy' target='_blank' rel='noreferrer'>
          Privacy Policy
        </FooterLink>
      </FooterElement>
      <FooterElement>
        <FooterLink href='https://www.help.evpassport.com/hc/en-us' target='_blank' rel='noreferrer'>
          Help Center
        </FooterLink>
      </FooterElement>
      <FooterElement>
        <FooterLink href='https://status.evpassport.com/' target='_blank' rel='noreferrer'>
          Status
        </FooterLink>
      </FooterElement>
      <FooterElement>
        <FooterLink href='https://changelog.evpassport.com' target='_blank' rel='noreferrer'>
          Changelog
        </FooterLink>
      </FooterElement>
    </FooterList>
  </FooterContainer>
);
