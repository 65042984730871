declare global {
  interface Window {
    REACT_APP_API_PREFIX: string;
    REACT_APP_API_NEW_PREFIX: string;
    REACT_APP_BASE_URL_NEW: string;
    REACT_APP_ENV: string;
    REACT_APP_BASE_URL: string;
    FAST_REFRESH: string;
    ESLINT_NO_DEV_ERRORS: string;
    REACT_APP_GOOGLE_MAPS_KEY: string;
    REACT_APP_OAUTH_URL: string;
    REACT_APP_OAUTH_CLIENT: string;
    RUM_APPLICATION_ID: string;
    RUM_CLIENT_TOKEN: string;
    RUM_ENABLED: string;
    RUM_SITE: string;
    RUM_SESSION_SAMPLE_RATE: string;
    RUM_SESSION_REPLAY_SAMPLE_RATE: string;
    RUM_ENV: string;
    GA_ENABLED: string;
    GA_TRACKING_ID:string;
  }
}

export const REACT_APP_API_PREFIX: string = window.REACT_APP_API_PREFIX || '';
export const REACT_APP_API_NEW_PREFIX: string = window.REACT_APP_API_NEW_PREFIX || '';
export const REACT_APP_ENV: string = window.REACT_APP_ENV || '';
export const REACT_APP_BASE_URL_NEW: string = window.REACT_APP_BASE_URL_NEW || '';
export const REACT_APP_BASE_URL: string = window.REACT_APP_BASE_URL || '';
export const FAST_REFRESH: string = window.FAST_REFRESH || '';
export const ESLINT_NO_DEV_ERRORS: string = window.ESLINT_NO_DEV_ERRORS || '';
export const REACT_APP_GOOGLE_MAPS_KEY: string = window.REACT_APP_GOOGLE_MAPS_KEY || '';
export const REACT_APP_OAUTH_URL: string = window.REACT_APP_OAUTH_URL;
export const REACT_APP_OAUTH_CLIENT: string = window.REACT_APP_OAUTH_CLIENT;
export const RUM_APPLICATION_ID: string = window.RUM_APPLICATION_ID || '';
export const RUM_CLIENT_TOKEN: string = window.RUM_CLIENT_TOKEN || '';
export const RUM_ENABLED: string = window.RUM_ENABLED || '';
export const RUM_SESSION_SAMPLE_RATE: string = window.RUM_SESSION_SAMPLE_RATE || '';
export const RUM_SESSION_REPLAY_SAMPLE_RATE: string = window.RUM_SESSION_REPLAY_SAMPLE_RATE || '';
export const RUM_ENV: string = window.RUM_ENV || '';
export const GA_ENABLED: string = window.GA_ENABLED || '';
export const GA_TRACKING_ID: string = window.GA_TRACKING_ID || '';